import React from 'react';
import { Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../../hooks/MediaQuery.hook';

const contentStyle: React.CSSProperties = {
    height: '100px',
    color: '#ffffff',
    lineHeight: '100px',
    textAlign: 'center',
    marginTop:'20px',
    marginLeft:'20px',
    marginRight: '20px',
    justifyContent: 'center',
    display: 'flex'
  };

export default function Slider({data}: any) {
  const matches = useMediaQuery('(min-width: 768px)');


  return (
    <>
        <Typography.Title level={4} style={{color: 'white'}}>{data[0]?.teamName ? 'Top Teams': 'Top Leagues'}</Typography.Title>
        <Row gutter={24} style={{display: 'flex',justifyContent: 'center'}}>
        {
            data?.map((item: any, index:number)=> {
                return <Row key={index} style={contentStyle}>
                    <Link to={item?.teamName ? `/teams/${item?.teamName}/${item.id}`: `/leagues/${item?.leagueName}/${item.id}`}>
                    <img style={{width: matches ? '60px' : '20px'}} src={item?.teamImage ??item?.leagueImage } alt={item?.teamName ?? item?.leagueName} />
                    </Link>
                </Row>
            })
        }
        </Row>
    </>
  );
};