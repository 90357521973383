/* eslint-disable no-console */
import { doPost } from '../utils/request';

export const getFixtures = async (data: any) => {
  try {
    const response = await doPost('/xrh', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getTeamFixtures = async (data: any) => {
  try {
    const response = await doPost('/getTeamFixtures', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getLeagueFixtures = async (data: any) => {
  try {
    const response = await doPost('/getLeagueFixtures', data);
    return response;
  } catch (err: any) {
    return err;
  }
};


export const getdbTeams = async (data: any) => {
  try {
    const response = await doPost('/getdbTeams', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getdbLeagues = async (data: any) => {
  try {
    const response = await doPost('/getdbLeagues', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getFixtureByName = async (data: any) => {
  try {
    const response = await doPost('/xrhs', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getIframe = async (data: any) => {
  try {
    const response = await doPost('/getIframe', data);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getStreamLinks = async (fixture: any) =>{
  const filter = {
    categoryName: fixture?.categoryName,
    subCat: fixture?.subCategoryName,
    teamA: fixture?.game.teamA,
    teamB:  fixture?.game.teamB,
    link: fixture?.game?.sourceLink,
  }
  try {
    const response = await doPost('/getLiveStreams', filter);
    return response;
  } catch (err: any) {
    return err;
  }
}

export const sendMail = async (body: any) =>{
  try {
    const response = await doPost('/sendMessage', body);
    return response;
  } catch (err: any) {
    return err;
  }
}

export const getStandings = async (body: any) =>{
  try {
    const response = await doPost('/stxf', body);
    return response;
  } catch (err: any) {
    return err;
  }
}

export const addStream = async (body: any) =>{
  try {
    const response = await doPost('/addStream', body);
    return response;
  } catch (err: any) {
    return err;
  }
}

export const updateStream = async (body: any) =>{
  try {
    const response = await doPost('/getLiveStreams', body);
    return response;
  } catch (err: any) {
    return err;
  }
}

export const authenticateUser = async (body: any) =>{
  try {
    const response = await doPost('/authenticateUser', body);
    return response;
  } catch (err: any) {
    return err;
  }
}