import dayjs from 'dayjs';

export function getTimer(fixture: any): any {
    let timeDifference = 0;
    let timer = '';
    if(fixture?.matchDate){
        const now = new Date();
        const matchDateTime = new Date(fixture?.matchDate);
        timeDifference = matchDateTime.getTime() - now.getTime();
        if(timeDifference > 0){
            const m = Math.floor((timeDifference / (1000 * 60)) % 60);
            const h = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            const d = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const days = d > 1 ?  `${d} days, ` : d == 1 ? `${d} day,`: '';
            const hours = h > 1 ?  `${h} hrs, ` : h == 1 ?  `${h} hr,`: '';
            const mins = m > 1 ?  `${m} mins` : m == 1 ? `${m} min`: '';
            timer = `In ${days} ${hours} ${mins} `;
        }else {
            if((timeDifference + 10800000) < 0 ) {
                timer = dayjs(matchDateTime).format('hh:mm A');
              }else {
                timer = 'Live'
              }
        }
    }
    return timer;
}