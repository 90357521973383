import { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { getdbLeagues, getdbTeams } from '../../apis/fixture.api';
import { Carousel} from 'antd';
import Slider from './Slider';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export default function Teams() {
    const matches = useMediaQuery('(min-width: 768px)');
    const [loader, setLoader] = useState<boolean>(false);
    const [sliderLeagues, setSliderLeagues] = useState<any>([]);
    const [sliderTeams, setSliderTeams] = useState<any>([]);

    function splitArrayIntoSubarrays(array: any, subarrayLength: number) {
        const subarrays = [];
        for (let i = 0; i < array.length; i += subarrayLength) {
            subarrays.push(array.slice(i, i + subarrayLength));
        }
        return subarrays;
    }

    const getLeagues = async ()=> {
        setLoader(true);

        const response = await getdbLeagues({});
        const subarrayLength = matches ? 9: 6;
        const result = splitArrayIntoSubarrays(response?.data, subarrayLength);
        setSliderLeagues(result);

        setLoader(false);
        }

    const getTeams = async ()=> {
        setLoader(true);

        const response = await getdbTeams({});
        const subarrayLength = matches ? 13: 6;
        const result = splitArrayIntoSubarrays(response?.data, subarrayLength);
        setSliderTeams(result);

        setLoader(false);
    }

    useEffect(() => {
        getLeagues();
        getTeams();
        
    }, []);

    return (
        <>
            <Carousel autoplay>
                {
                    sliderLeagues?.map((sliderData:any, index: number)=>{
                        return <Slider key={index} data={sliderLeagues.length ? sliderData: []}></Slider>
                    })
                }
                {
                    sliderTeams?.map((sliderData:any, index: number)=>{
                        return <Slider key={index} data={sliderTeams.length ? sliderData: []}></Slider>
                    })
                }
            </Carousel>
            {loader ? <Loader /> : <></>}
        </>

    );
}
  